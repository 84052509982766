import React from "react"

import { getDateDuration } from "../../utils/getDateDuration"
import BookmarkIcon from "../../images/bookmark.svg"
import locationIcon from "../../images/location.svg"
import suitcase from "../../images/suitcase.svg"
import coin from "../../images/coin.svg"

import "./searchResultCard.scss"
import { Link } from "gatsby"

export default function SearchResultCard(props) {
  const date = React.useMemo(() => {
    return getDateDuration(props?.job_created)
  }, [props.job_created])

  const getPostedTimeDiff = React.useCallback(() => {
    if (date?.years > 0) {
      return date?.years + "y "
    } else if (date?.days > 0) {
      return date?.days + "d "
    } else if (date?.hours > 0) {
      return date?.hours + "h "
    } else if (date?.minutes > 0) {
      return date?.minutes + "m "
    } else {
      return "over 2 month"
    }
  }, [date])

  return (
    <div className="wdn-search-result-card">
      <div className="wsrc-header d-flex justify-content-between align-items-center">
        <div className="wsrch-wrapper d-flex justify-content-start align-items-center gap-8px">
          <div className="company-logo">
            <img src={props.companyLogo} alt="" className="img-fluid" />
          </div>
          <div className="wsrch-content">
            <Link to={`/new-search-page/${props.companyName.toLowerCase()}`}>
              <strong>{props.post}</strong>
            </Link>
            <p>{props.companyName}</p>
          </div>
        </div>
        <button type="button" className="bookmark-icon">
          <img src={BookmarkIcon} alt="" className="img-fluid" />
        </button>
        {/* <div className="row align-items-center">
          <div className="col-9">
            <div className="row">
              <div className={props.isAside ? "col-2 " : "col-2 col-xl-3"}>
                <div className="company-logo">
                  <img src={props.companyLogo} alt="" className="img-fluid" />
                </div>
              </div>
              <div className={props.isAside ? "col-10 " : "col-10 col-xl-9"}>
                <a href={`/new-jobs/${props.companyName}`}>
                  <strong>{props.post}</strong>
                </a>
                <p>{props.companyName}</p>
              </div>
            </div>
          </div>

          <div className="col-3">
            <button type="button" className="bookmark-icon">
              <img src={BookmarkIcon} alt="" className="img-fluid" />
            </button>
          </div>
        </div> */}
      </div>
      <span className="job-type mb-12px d-inline-block">{props.jobType}</span>
      {props?.brief ? <p>{props.brief}</p> : ""}

      <ul>
        <li>
          <span>
            <img src={locationIcon} alt="" className="img-fluid" />
          </span>
          <p>{props.address}</p>
        </li>
        <li className="has-children">
          <ul>
            {props?.minExperience ? (
              <li>
                <span>
                  <img src={suitcase} alt="" className="img-fluid" />
                </span>
                <p>{props.minExperience}</p>
              </li>
            ) : (
              ""
            )}
            {props?.baseSalary ? (
              <li>
                <span>
                  <img src={coin} alt="" className="img-fluid" />
                </span>
                <p>Rs. {props.baseSalary}</p>
              </li>
            ) : (
              ""
            )}
          </ul>
        </li>
      </ul>

      <div className="row justify-space-between">
        <div className="col-8">
          <p>{getPostedTimeDiff()}</p>
        </div>
        <div className="col-4 text-end">
          <a href="#" className="btn btn-filled-v4">
            Apply
          </a>
        </div>
      </div>
    </div>
  )
}
