export function getDateDuration(date) {
  // Specify the specific time
  const specificTime = new Date(date)

  // Get the current time
  const currentTime = new Date()

  // Calculate the duration in milliseconds
  const durationMs = currentTime - specificTime

  // Convert the duration to seconds, minutes, hours, and days
  const seconds = Math.floor(durationMs / 1000) % 60
  const minutes = Math.floor(durationMs / (1000 * 60)) % 60
  const hours = Math.floor(durationMs / (1000 * 60 * 60)) % 24
  var days = Math.floor(durationMs / (1000 * 60 * 60 * 24)) % 365
  var years = Math.floor(durationMs / (1000 * 60 * 60 * 24 * 365))

  return { years, days, hours, minutes, seconds }
}
